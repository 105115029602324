import React from 'react';
import {Stack} from "@material-ui/core";
import {Img} from "react-image";

const Marketing = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'top',
                alignItems: 'center',
                height: '100vh',
                width: '100%'
            }}
        >
            <h2>Marketing</h2>
            <br></br>
            <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="flex-start">
                <span className="Span-text">AWS Pinpoint Architecture.</span>
            </Stack>
            <span className="Span-text-desc">Home owners and contractors can communicate using the the managed service Pinpoint. This services lets users communicate using a two-way communication supporting multiple channels such as Email, SMS and push notifications. It also support journey maps and trigger events during mpbile actiond. such as, a new project posted in a particular geographic area. Below is the initial architecture to support Hometask AI.
                 </span>
            <Img src='http://hometask.io/images/Pinpoint.png' alt="help"/>

        </div>
    );
};

export default Marketing;


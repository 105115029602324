import React from 'react';
import {Stack} from "@material-ui/core";
import {Img} from "react-image";


const Home = () => {
    return (
        <div
            style={{
                display: 'flex',
                color: '#5294CF',
                flexDirection: 'column',
                justifyContent: 'top',
                alignItems: 'center',
                height: '100vh',
                width: '100%',
                backgroundImage: `url("http://hometask.io/images/home-page-hometaskai.jpg")`
            }}
        >

            <br></br>
            <br></br>
            <br></br>
            <Stack direction="column" spacing={1} justifyContent="flex-start">
                <span className="Span-home-title">Use Artificial Intelligence and AWS Cloud technology to make your home owner experience innovative.</span>
                <br></br>  <ul>
                    <li>
                        <span className="Span-home">Home Project Management</span>
                    </li>
                    <li>
                        <span className="Span-home">Mobile & Web Apps</span>
                    </li>
                    <li>
                        <span className="Span-home">Video AI Processing</span>
                    </li>
                    <li>
                        <span className="Span-home">Smart Geo location</span>
                    </li>
                </ul>

            </Stack>





        </div>
    );
};

export default Home;


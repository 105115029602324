import React from 'react';
import {Stack} from "@material-ui/core";
import {Img} from "react-image";

const Ui = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'top',
                alignItems: 'center',
                height: '100vh',
                width: '100%'
            }}
        >
            <h2>Mobile UI</h2>
            <br></br>

            <span className="Span-text-desc">Mobile apps and dashboards are provided to facilitate collaboration and engagement.
                 </span>
            <br></br>
            <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="flex-start">
                <Img src='http://hometask.io/images/hometask-page1.png' alt="help" class="mobile-image"/>
                <Img src='http://hometask.io/images/hometask-page2.png' alt="help" class="mobile-image"/>
                <Img src='http://hometask.io/images/hometask-page3.png' alt="help" class="mobile-image"/>
            </Stack>

            <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="flex-start">
                <Img src='http://hometask.io/images/hometask-page4.png' alt="help" class="mobile-image"/>
                <Img src='http://hometask.io/images/hometask-page5.png' alt="help" class="mobile-image"/>
                <Img src='http://hometask.io/images/hometask-page6.png' alt="help" class="mobile-image"/>
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="flex-start">
                <Img src='http://hometask.io/images/hometask-page7.png' alt="help" class="mobile-image"/>
                <Img src='http://hometask.io/images/hometask-page8.png' alt="help" class="mobile-image"/>
                <Img src='http://hometask.io/images/hometask-page9.png' alt="help" class="mobile-image"/>
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="flex-start">
                <Img src='http://hometask.io/images/hometask-page10.png' alt="help" class="mobile-image"/>
                <Img src='http://hometask.io/images/hometask-page11.png' alt="help" class="mobile-image"/>
            </Stack>

        </div>
    );
};

export default Ui;

import React, {useContext, useEffect} from 'react';

import {
    Nav,
    NavLink,
    Bars,
    NavMenu,
    NavBtn,
    NavBtnLink,
} from './NavbarElements';

const Navbar = () => {

    return (
        <>
            <Nav>

                <Bars/>
                <NavMenu>
                    < NavLink to='/howitworks'>
                        How it works
                    </NavLink>
                    < NavLink to='/architecture'>
                        Architecture
                    </NavLink>
                    <NavLink to='/ui'>
                        Mobile UI
                    </NavLink>
                    <NavLink to='/db'>
                        DynamoDB Design
                    </NavLink>
                    <NavLink to='/marketing'>
                        Marketing
                    </NavLink>
                </NavMenu>
            </Nav>
        </>
    );
};

export default Navbar;
